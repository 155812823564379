<template>
  <div class="view-men">
    <h3>版本生效日期：2021年10月08日</h3>
    <p class="tex-indent">欢迎您使用焱十一！我们非常重视您的隐私保护和个人信息保护。本隐私政策适用于您访问、使用焱十一时提交、留存的信息。</p>
    <p class="tex-indent">我们尽量以更容易被您所理解的方式向您说明，在您使用焱十一时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</p>
    <p class="tex-indent">若您访问、使用焱十一，即表示您认同我们在本隐私政策中所述的内容。在将您的信息用于本隐私政策未涵盖的用途时，我们会事先征求您的同意。</p>
    <p class="tex-indent">本隐私政策将帮助您了解以下内容：</p>
    <p class="tex-indent">一、我们如何收集和使用您的个人信息</p>
    <p class="tex-indent">二、我们如何使用 Cookies 和同类技术</p>
    <p class="tex-indent">三、我们如何共享、转让、公开披露您的个人信息</p>
    <p class="tex-indent">四、我们如何保护和保存您的个人信息</p>
    <p class="tex-indent">五、您的权利</p>
    <p class="tex-indent">六、未成年人的个人信息保护</p>
    <p class="tex-indent">七、您的个人信息如何在全球范围转移</p>
    <p class="tex-indent">八、通知与修订</p>
    <p class="tex-indent">九、如何联系我们</p>
    <p class="tex-indent-o">
      我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息:权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，焱十一承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。请您在使用我们的服务与/或产品前，仔细阅读并了解本《隐私政策》。
    </p>
    <h3>一、我们如何收集和使用您的个人信息</h3>
    <p class="tex-indent">
      焱十一仅会出于本政策所述的以下目的，收集和使用您的个人信息：个人信息是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，不包括匿名化处理后的信息。我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用服务与/或产品过程中主动提供或因使用服务与/或产品而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
    </p>
    <p class="tex-indent">（一）焱十一仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
    <p class="tex-indent">
      我们的服务与/或产品包括一些核心功能，这些功能包含了实现网络竞拍所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的服务与/或产品。这些功能包括：
    </p>
    <p class="tex-indent">1、实现网络竞拍所必须的功能</p>
    <p class="tex-indent">（1）帐户注册、登录与验证</p>
    <p class="tex-indent">
      当您注册焱十一帐户时，您至少需要向我们提供您准备使用的帐户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱），我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。通过SIM卡信息识别并显示对应的运营商。您的帐户名为您的默认昵称，您可以修改和补充您的昵称、性别、生日、兴趣爱好以及您的实名认证相关信息，这些信息均属于您的“帐户信息”。您补充的帐户信息将有助于我们为您提供个性化的拍卖标的的推荐和更优的网络竞拍体验，但如果您不提供这些补充信息，不会影响您使用网络竞拍的基本功能。
    </p>
    <p class="tex-indent">
      当您成功注册焱十一帐号后，可以使用焱十一帐号登录焱十一，登录时您需要向我们提供您的焱十一帐户信息。若您不提供这类信息，您将无法登录和在焱十一进行网络竞拍，此时您可使用浏览和搜索功能，我们将不会收集您的上述个人信息。</p>
    <p class="tex-indent">（2）拍卖标的/服务信息展示和搜索</p>
    <p class="tex-indent">
      为了让您快速地找到您所需要的拍卖标的，我们可能会收集您使用我们的服务与/或产品的设备信息（包括设备名称、设备型号、MAC地址及IMEI、设备识别码、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、设备所在位置相关信息（包括您授权的GPS位置信息以及WLAN接入点、蓝牙和基站传感器信息）、浏览器类型来为您提供拍卖标的信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。如果您不想看到推荐的拍卖标的或服务等信息，您可以通过长按被推荐的拍卖标的或服务图片，根据提示弹窗选择屏蔽相关拍卖标的或服务类目。
    </p>
    <p class="tex-indent">您也可以通过搜索来精准地找到您所需要的拍卖标的或拍卖公司。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的拍卖标的或拍卖公司。</p>
    <p class="tex-indent">
      请您注意，单独的设备信息、浏览器类型或仅搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的单独的设备信息、浏览器类型或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的设备信息、浏览器类型或搜索关键词信息作为您的个人信息，按照本隐私政策对其进行处理与保护。
    </p>
    <p class="tex-indent">
      为了将您感兴趣的拍卖标的或拍卖公司信息展示给您，或在您搜索时向您展示您可能希望找到的拍卖标的，我们可能会收集您的订单信息、浏览信息、您的兴趣爱好（您可以在帐户设置中选择）进行数据分析以形成用户画像。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。如果您不希望受个性化内容的影响，您可以在拍卖标的展示页选择按照分类进行浏览和查找拍卖标的和拍卖公司。
    </p>
    <p class="tex-indent">（3）订单生成及订单管理</p>
    <p class="tex-indent">
      当您准备对您成功竞得的拍卖标的进行结算时，焱十一会生成您竞得该拍卖标的的订单。该订单中会载明订单号、您的网络竞拍号、您所竞得的拍卖标的信息、下单时间、您应支付的拍品款及支付方式。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。
    </p>
    <p class="tex-indent">
      在实现部分特殊类型拍卖标的交易或服务时还需要您提供其他必要信息，例如您在焱十一上竞得了海外的拍卖标的且需要运回国内时，您可能需要根据国家法律法规或服务提供方（包括海关、包装运输服务提供方等）的要求提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的出生证明、户口本、身份证、军官证、护照、驾驶证等载明您身份信息的证件复印件或号码）、您本人的照片或视频、姓名、电话号码、完税证明。
    </p>
    <p class="tex-indent">
      上述所有信息构成您的“订单信息”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
    </p>
    <p class="tex-indent">
      您知晓并同意，您和拍卖公司才是交易的双方，您的订单信息将实时共享给拍卖公司；当您需要包装运输服务时，您和您指定的包装运输服务提供方是交易的双方，您的订单信息和收货信息将实时共享给您指定的包装运输服务提供方。</p>
    <p class="tex-indent">（4）支付功能</p>
    <p class="tex-indent">
      在您结算时，您可以选择与焱十一合作的第三方支付机构（包括微信支付及银联、网联等支付通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息，包括您的银行卡号、有效期、还款金额在内的银行卡（含储蓄卡、信用卡）支付必要信息与这些支付机构共享以实现其确认您的支付指令并完成支付。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
    </p>
    <p class="tex-indent">（5）交付拍卖标的功能</p>
    <p class="tex-indent">
      拍卖标的的交付由拍卖公司负责，您可以去拍卖公司指定地点领取您的拍卖标的，也可以联系拍卖公司或第三方运输公司邮寄拍卖标的。当您需要焱十一协助时，您可以在下单时选择由您指定的包装运输服务提供方，并至少填写您的收货人姓名、收货地址以及手机号码。您知晓并同意，包装运输服务提供方是您自行指定的，并授权焱十一将您的订单信息共享给该包装运输服务提供方，包装运输服务提供方会在上述环节内使用您的订单信息以确保您竞得的拍卖标的能够安全送达。
    </p>
    <p class="tex-indent">包装运输服务方的配送员在为您提供配送服务的同时，基于某些业务法律要求实名认证的需求，会协助您完成实名认证。</p>
    <p class="tex-indent">（6）客服功能</p>
    <p class="tex-indent">
      我们的电话客服功能会使用您的帐号信息和订单信息。为保证您的帐号安全，我们的客服会使用您的帐号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更联系人或联系电话。
    </p>
    <p class="tex-indent">（7）专属业务人员服务功能</p>
    <p>
      如您无法参与网络竞拍时，您可以联系您的专属业务人员，由您的专属业务人员根据您的委托出价协助您完成竞拍。当您需要专属业务人员提供服务时，您有可能提供给出上述信息外的其他信息，包括您的网络竞拍号、拟参拍的拍卖会、拟竞拍的拍卖标的、您的委托出价、您的最高出价限额、您的支付方式、您的收货信息等。
    </p>
    <p class="tex-indent">2、保障交易安全所必须的功能</p>
    <p class="tex-indent">
      为保障您使用我们的服务与/或产品时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护帐号安全，我们需要收集您的设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的帐号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对焱十一系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用焱十一过程中对您的服务及帐号安全进行保护。
    </p>
    <p class="tex-indent">3、开展内部审计、数据分析和研究，改善我们的服务</p>
    <p class="tex-indent">
      在不泄露您个人信息的前提下，焱十一使用收集的信息来提供并改进焱十一的服务，并基于对拍卖标的、拍卖行业的市场分析，我们会和拍卖公司、拍卖协会、公众共享拍卖标的的竞价信息、成交信息，但这些信息将不会包含您的任何身份识别信息。</p>
    <p class="tex-indent">（二）您可自主选择提供的个人信息的情形</p>
    <p class="tex-indent">
      1、为使您网络竞拍更加便捷或更具有乐趣，从而提升您在焱十一的竞拍体验，我们的以下扩展功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息，您依然可以进行网上竞拍，但您可能无法使用为您带来网上竞拍的乐趣的扩展功能或在竞拍某些拍卖标的时需要重复填写一些信息。
    </p>
    <p class="tex-indent">这些扩展功能包括：</p>
    <p class="tex-indent">
      （1）基于位置信息的个性化推荐功能（LOCATION）：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您推荐您所在区域可以购买的拍卖标的，例如向您推荐离您最近的拍卖会现场，方便您去实地查看拍卖标的。
    </p>
    <p class="tex-indent">
      （2）基于摄像头（相机）的扩展功能（CAMERA）：您可以使用这项扩展功能完成视频拍摄、拍照、扫码以及人脸识别登录的功能。当您使用人脸识别登录时我们会收集您的面部信息，且在您授权范围内限制使用。未来我们可能会将人脸识别技术应用于更多场景，届时我们会再次与您确认您是否愿意我们使用您的面部信息来实现这些扩展功能。
    </p>
    <p class="tex-indent">（3）基于图片/视频上传的扩展功能：您可以在焱十一上传您的照片/视频来实现晒单、评价及问答功能，我们会使用包含您所上传图片或视频的晒单、评价及问答内容。</p>
    <p class="tex-indent">
      （4）基于语音技术的扩展功能（READ_AUDIO）：您可以直接使用麦克风来进行语音竞价或与我们的客服进行咨询和互动。在这些功能中我们会收集您的录音内容以识别您的竞价金额、竞拍需求以及客服需求。</p>
    <p class="tex-indent">
      （5）基于通讯录信息的扩展功能（READ_CONTACTS）：在您同意开启通讯录权限后我们将收集您的通讯录信息以方便您在网上竞拍时不需再手动输入您通讯录中联系人的信息（例如您可以直接将通讯录中的电话号码设为联系人）。为了增加您网上竞拍时的社交乐趣，在获得您的同意时我们也可以判断您的好友是否也同为焱十一用户，并在焱十一上为你们的交流建立联系。
    </p>
    <p class="tex-indent">
      （6）基于日历权限的扩展功能（READ_CALENDAR）：当您使用焱十一服务时，您可以通过开启日历权限便捷管理您的自定义事项、设定重要事项提醒。若您不开启此权限，您将无法使用日历管理相关的功能，但不影响您使用其他服务。
    </p>
    <p class="tex-indent">
      （7）基于读取电话状态的扩展功能(READ_PHONE_STATE)：当您运行焱十一APP时，我们会向您申请获取此权限，目的是正常识别您的本机识别码，以便完成安全风控、进行统计和服务推送。
    </p>
    <p class="tex-indent">
      （8）基于读取/写入外置存储器的扩展功能(WRITE_EXTERNAL_STORAGE)(
      READ_EXTERNAL_STORAGE)：在您开启我们可读取/写入您设备存储的权限后，我们将自您设备存储空间内读取或向其中写入图片、文件、崩溃日志信息等必要信息，用于向您提供信息发布或在本地记录崩溃日志信息等功能，保障客户端的稳定运行。
    </p>
    <p class="tex-indent">
      （9）基于面容ID/触控ID权限的扩展功能：若您使用支持人脸识别的苹果设备，您可以使用焱十一APP中的面容ID支付、面容ID解锁功能；若您使用支持指纹识别的苹果设备，您可以使用焱十一APP中的指纹支付、指纹解锁功能。您需要在您的设备上提前录入面容信息或指纹信息，并在使用服务时在您的设备上完成信息验证。我们需要使用您的面容ID/触控ID权限以便接收验证结果，但我们并不收集您的面容ID/触控ID信息。若您不开启此权限，您将无法使用前述服务，但不影响您选择其他支付或解锁方式。
    </p>
    <p class="tex-indent">
      （10）基于NFC的扩展功能：当您使用Android手机选择闪付卡进行支付时，需要开启您的NFC权限。若您不开启此权限，您将无法使用前述服务，但不影响您选择其他支付方式。
    </p>
    <p class="tex-indent">
      （11）基于剪切板的扩展功能：当您复制拍卖标的信息、标题、短信验证码，分享或接收被分享信息时，您的剪切板功能可能会被调用。剪切板信息仅会在您的设备上进行处理，我们不会存储您的剪切板信息用于任何其他途径。</p>
    <p class="tex-indent">
      2、上述扩展功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）、蓝牙、日历、通讯录、电话状态、外部存储、面容ID/触控ID、NFC权限，以实现这些功能所涉及的信息的收集和使用。您可以在焱十一APP或设备系统中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭（我们会指引您在您的设备系统中完成设置）。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。除此以外，我们在相关业务功能中可能还需要您开启设备的其他访问权限，详细权限和使用目的如下：
    </p>
    <p class="tex-indent">（1）android.permission.INTERNET（访问网络权限）：实现应用程序联网</p>
    <p class="tex-indent">（2）android.permission.ACCESS_WIFI_STATE（获取WiFi状态权限）：监控网络变化，提示用户当前网络环境</p>
    <p class="tex-indent">（3）android.permission.ACCESS_NETWORK_STATE（获取网络状态权限）：监控网络变化，提示用户当前网络环境</p>
    <p class="tex-indent">（4）android.permission.SYSTEM_ALERT_WINDOW（悬浮窗权限）：观看直播、短视频悬浮窗播放</p>
    <p class="tex-indent">（5）android.permission.ACCESS_BACKGROUND_LOCATION（支持后台访问位置权限）：保持持续定位能力</p>
    <p class="tex-indent">（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
    <p class="tex-indent">1、与国家安全、国防安全有关的；</p>
    <p class="tex-indent">2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p class="tex-indent">3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p class="tex-indent">4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p class="tex-indent">5、所收集的个人信息是个人信息主体或监护人自行向社会公众公开的；</p>
    <p class="tex-indent">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p class="tex-indent">7、根据您的要求签订合同所必需的；</p>
    <p class="tex-indent">8、用于维护所提供的服务与/或产品的安全稳定运行所必需的，例如发现、处置服务与/或产品的故障；</p>
    <p class="tex-indent">9、为合法的新闻报道所必需的；</p>
    <p class="tex-indent">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
    <p class="tex-indent">11、法律法规规定的其他情形。</p>
    <p class="tex-indent">（四）我们从第三方获得您个人信息的情形</p>
    <p class="tex-indent">
      我们可能从第三方获取您授权共享的帐户信息（头像、昵称及授权页面提示的其他信息），并在您同意本隐私政策后将您的第三方帐户与您的焱十一帐户绑定，使您可以通过第三方帐户直接登录并使用我们的服务与/或产品。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规的前提下，使用您的这些个人信息。您也可以使用本机号码来快捷登录，我们会将您的网络信息提供给运营商完成注册或登录。
    </p>
    <p class="tex-indent">（五）您个人信息使用的规则</p>
    <p class="tex-indent">1、我们会根据本隐私政策的约定并为实现我们的服务与/或产品功能对所收集的个人信息进行使用。</p>
    <p class="tex-indent">
      2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
    </p>
    <p class="tex-indent">
      3、请您了解并同意，您在使用我们的服务与/或产品时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的服务与/或产品期间持续授权我们使用。在您注销帐号时，我们将停止使用并删除您的个人信息，具体请详见本隐私政策附件《焱十一帐户注销须知》。
    </p>
    <p class="tex-indent">4、我们会对我们的服务与/或产品使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务与/或产品的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
    <p class="tex-indent">5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</p>
    <p class="tex-indent">
      6、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会实现征求您的明示同意。请您理解，我们向您提供的服务与/或产品将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他服务与/或产品时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项服务与/或产品，但不影响您使用现有服务与/或产品。
    </p>
    <p class="tex-indent">（六）焱十一APP涉及嵌入第三方代码、插件传输个人信息的情形逐项列举，请见附件《SDK列表》。</p>
    <h3>二、我们如何使用Cookies和同类技术</h3>
    <p class="tex-indent">（一）Cookies的使用</p>
    <p class="tex-indent">1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies
      是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的竞拍偏好或您历史成交订单等数据进而为您提供竞拍的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及帐户或数据安全。
    </p>
    <p class="tex-indent">2、我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可以清除计算机上保存的所有Cookies，大部分网络浏览器都设有阻止Cookie
      的功能。但如果您这么做，则需要在每一次访问焱十一时亲自更改用户设置，但您可能因为该等修改而无法登录或使用依赖于Cookie的服务或功能，且您之前所记录的相应信息也全都会被删除，可能会对您所使用服务的安全性产生一定影响。</p>
    <p class="tex-indent">（二）网络Beacon和同类技术的使用</p>
    <p class="tex-indent">除Cookies 外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些电子图像（称为"单像素" GIF 文件或 "网络
      Beacon"）。我们使用网络Beacon的方式有：</p>
    <p class="tex-indent">1、通过在焱十一网站上使用网络Beacon，计算用户访问数量，并通过访问 Cookies 辨认注册的焱十一用户。</p>
    <p class="tex-indent">2、通过得到的Cookies信息，为您提供个性化服务。</p>
    <p class="tex-indent">(三)Do Not Track(请勿追踪)</p>
    <p class="tex-indent">很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track
      请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。</p>
    <h3>三、我们如何共享、转让、公开披露您的个人信息</h3>
    <p class="tex-indent">（一）共享：</p>
    <p class="tex-indent">（1）事先获得您明确的同意或授权；</p>
    <p class="tex-indent">（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
    <p class="tex-indent">（3）在法律法规允许的范围内，为维护焱十一、焱十一的关联方或合作伙伴、您或其他焱十一用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
    <p class="tex-indent">（4）只有共享您的个人信息，才能实现我们的服务与/或产品的核心功能或提供您需要的服务；</p>
    <p class="tex-indent">（5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p class="tex-indent">（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的规则）或其他的法律文件约定所提供；</p>
    <p class="tex-indent">（7）基于学术研究而使用；</p>
    <p class="tex-indent">（8）基于符合法律法规的社会公共利益而使用。</p>
    <p class="tex-indent">
      2、为实现基于焱十一帐户向您提供相关服务和/或产品，向您展示可能感兴趣的内容，保护您的帐户与交易安全，我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私政策的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
    </p>
    <p class="tex-indent">
      3、我们可能会向合作伙伴等第三方共享您的订单信息、帐户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：
    </p>
    <p class="tex-indent">
      （1）拍卖公司及您指定的包装运输服务提供方。我们会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、支付服务、数据处理、您指定的物流配送服务等。我们共享这些信息的目的是可以实现我们服务与/或产品的核心功能，比如将您的网络竞拍号、竞价金额、成交信息、联系方式共享给拍卖公司，以促使拍卖交易的完成。
    </p>
    <p class="tex-indent">
      （2）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们服务与/或产品的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后参与了委托方举办的拍卖会，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
    </p>
    <p class="tex-indent">
      4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。
    </p>
    <p class="tex-indent">
      5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护焱十一、您或其他焱十一用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
    </p>
    <p class="tex-indent">（二）转让</p>
    <p class="tex-indent">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p class="tex-indent">1、事先获得您明确的同意或授权；</p>
    <p class="tex-indent">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
    <p class="tex-indent">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的服务规则）或其他的法律文件约定所提供；</p>
    <p class="tex-indent">4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会 在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则 我们将要求该公司、组织重新向您征求授权同意。
    </p>
    <p class="tex-indent">（三）公开披露</p>
    <p class="tex-indent">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
    <p class="tex-indent">1、获得您明确同意后；</p>
    <p class="tex-indent">
      2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都将进行慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
    </p>
    <h3>四、我们如何保护和保存您的个人信息</h3>
    <p class="tex-indent">（一）我们保护您个人信息的技术与措施</p>
    <p class="tex-indent">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
    <p class="tex-indent">1、数据安全技术措施</p>
    <p class="tex-indent">我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。</p>
    <p class="tex-indent">在您的浏览器与“服务”之间交换数据(如信用卡信息)时受 SSL 加密保护；</p>
    <p class="tex-indent">我们同时对焱十一网站提供 https 安全浏览方式，确保用户数据在传输过程中的安全；</p>
    <p class="tex-indent">我们会使用加密技术确保数据的保密性;</p>
    <p class="tex-indent">我们会使用受信赖的保护机制防止数据遭到恶意攻击;</p>
    <p class="tex-indent">我们会部署访问控制机制，确保只有授权人员才可访问个人信息。</p>
    <p class="tex-indent">2、我们为保护个人信息采取的其他安全措施</p>
    <p class="tex-indent">焱十一通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</p>
    <p class="tex-indent">焱十一通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
    <p class="tex-indent">焱十一设信息保护专职岗位、数据安全应急响应组织来推进和保障个人信息安全。</p>
    <p class="tex-indent">我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
    <p class="tex-indent">
      3、我们仅允许有必要知晓这些信息的焱十一及焱十一关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与焱十一的合作关系。
    </p>
    <p class="tex-indent">4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
    <p class="tex-indent">
      5、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的帐号安全。在通过焱十一与第三方进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
    </p>
    <p class="tex-indent">6、安全事件处置</p>
    <p class="tex-indent">为应对个人信息泄露、损毁和丢失等可能出现的风险，焱十一制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。</p>
    <p class="tex-indent">
      若不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能造成的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、短信、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式进行公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>
    <p class="tex-indent">
      7、互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益遭受损失，我们将承担相应的法律责任。如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄露，尤其是您的帐户及密码发生泄露，请您立即通过本隐私政策【九、如何联系我们】中约定的联系方式联络我们，以便我们及时采取相应措施。
    </p>
    <p class="tex-indent">（二）您个人信息的保存</p>
    <p class="tex-indent">
      1、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
    </p>
    <p class="tex-indent">
      2、在您使用我们的服务与/或产品期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
    </p>
    <p class="tex-indent">
      3、请您注意，当您成功注销焱十一帐户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。
    </p>
    <p class="tex-indent">4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</p>
    <h3>五、您的权利</h3>
    <p class="tex-indent">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
    <p class="tex-indent">1、访问和更正您的个人信息</p>
    <p class="tex-indent">除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</p>
    <p class="tex-indent">
      （1）您的个人基本信息：PC端您可以在“我的”页面的“个人资料”菜单中查阅您提交给焱十一的个人信息，移动端具体路径为：首页--“我的”--“设置”--“编辑资料”。实名认证信息除外，您的实名认证信息是您通过实名认证时使用的姓名和身份证信息，如您需要变更您的实名认证信息，您可拨打400-616-9988服务热线申请变更。
    </p>
    <p class="tex-indent">
      （2）您的收货信息：PC端您可以通过访问“我的”页面--进入“收货地址”菜单中随时添加、更改、删除您的收货地址信息（包括收货人姓名、收货地址、收货人的电话号码或邮箱）；移动端具体路径为：首页--“我的”进入个人中心--“设置”--收货地址。您也可以将最常用的收货地址设置为默认地址。
    </p>
    <p class="tex-indent">
      （3）您的订单信息：PC端您可以通过访问“我的订单”页面查看您的所有已经完成、待付款的订单。移动端具体路径为：移动端首页--“我的”--“我的订单”。您可以选择删除已经完成和已取消的订单来删除您的订单信息，但这样可能导致我们无法准确判断您的竞拍信息而难以提供相应的服务，您也无法对删除的订单使用我们的晒单和评价功能。
    </p>
    <p class="tex-indent">（4）您的收藏信息：PC端您可以通过访问“我的”--“我的收藏”查看和取消您收藏的拍品、拍卖行和拍卖会。移动端具体路径为：首页--“我的”进入个人中心--“我的收藏”。</p>
    <p class="tex-indent">（5）您的竞拍记录：PC端您可以通过访问“我的”--“竞拍记录”查看和取消您收藏的拍品、拍卖行和拍卖会。移动端具体路径为：首页--“我的”进入个人中心--“竞拍记录”。</p>
    <p class="tex-indent">（6）您的浏览信息：您可以访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。移动端路径为：搜索历史记录：首页--“我的”进入我的艺得--浏览记录；</p>
    <p class="tex-indent">（7）您的评论信息：PC端您可以访问您的个人评论，或对拍品进行追评。移动端路径为：“我的”--“评价中心”。</p>
    <p class="tex-indent">（8）您的发票信息：您可以访问或更新或清除您的发票信息，移动端路径为首页--“我的”--“设置”--“发票”。</p>
    <p class="tex-indent">
      （9）您的档案信息：您可以在移动端“帐户设置-档案”中，主动添加您的性别、生日和学龄、设备型号，使您在竞拍相关拍卖标的时更加便捷，也便于我们向您推荐您可能感兴趣的拍卖标的或拍卖公司。您可通过此路径对您的上述信息进行删除和修改。
    </p>
    <p class="tex-indent">（10）对于您在使用我们的服务与/或产品过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</p>
    <p class="tex-indent">
      （11）您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。
    </p>
    <p class="tex-indent">2、删除您的个人信息</p>
    <p class="tex-indent">您在焱十一页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。</p>
    <p class="tex-indent">在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p class="tex-indent">（1）如果我们处理个人信息的行为违反法律法规；</p>
    <p class="tex-indent">（2）如果我们处理个人信息的行为违反了与您的约定；</p>
    <p class="tex-indent">（3）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p class="tex-indent">（4）如果您不再使用我们的服务或产品，或您注销了焱十一帐号；</p>
    <p class="tex-indent">
      （5）如果我们终止服务及运营。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。
    </p>
    <p class="tex-indent">3、改变您授权同意的范围或撤回您的授权</p>
    <p class="tex-indent">
      您可以通过删除信息、关闭设备功能、在焱十一网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销帐户的方式，撤回我们继续收集您个人信息的全部授权。</p>
    <p class="tex-indent">
      请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p class="tex-indent">4、注销帐户</p>
    <p class="tex-indent">
      您可以通过移动端APP访问我的-设置-注销账号-完成帐号注销。关于您注销帐户的方式以及您应满足的条件，请详见《焱十一帐户注销须知》。您注销帐户后，我们将停止为您提供服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。
    </p>
    <p class="tex-indent">5、获取您的个人信息副本</p>
    <p class="tex-indent">您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。</p>
    <p class="tex-indent">6、如果您不想接受我们给您发送的活动信息，您随时可通过以下方式取消：</p>
    <p class="tex-indent">（1）您可通过PC端帐户设置页面的邮件订阅菜单中取消邮件订阅的活动信息。</p>
    <p class="tex-indent">（2）您可以根据活动短信内容提示，来取消我们给您发送的手机短信。</p>
    <p class="tex-indent">（3）您可以通过移动端APP“消息中心-消息设置”设置是否接受我们通过“通知”推送给您的活动信息。</p>
    <p class="tex-indent">
      （4）我们会与第三方的或媒体（以下统称为“渠道”）合作基于您的个人信息向您推荐个性化的拍卖标的或拍卖公司。这些个人信息包括诸如您在本网站的浏览、参拍、成交等情况，访问本网站关联公司网站的情况及您在您的帐户设置中填写的兴趣爱好。渠道仅向我们提供了展示信息的窗口，窗口内容的链接是焱十一站内的个性化拍卖信息的展示，由焱十一进行管理，因此我们不会向广告商提供您的任何个人信息。而且我们在推荐窗内设置了屏蔽功能，您可选择屏蔽您不喜欢的广告。如您不愿意接受焱十一在单个渠道上的推荐服务，请您自行联系渠道进行关闭。您知晓并理解渠道是自主经营的实体，焱十一无法对其进行管理。
    </p>
    <p class="tex-indent">（5）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性取向等相关敏感内容的促销或拍品信息给您。</p>
    <p class="tex-indent">7、响应您的请求</p>
    <p class="tex-indent">
      如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们服务与/或产品时所产生的其他个人信息或者获取个人信息副本，或您认为焱十一存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至info@easyebid.com或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p class="tex-indent">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
    <p class="tex-indent">（1）与个人信息控制者履行法律法规规定的义务相关的；</p>
    <p class="tex-indent">（2）与国家安全、国防安全直接相关的；</p>
    <p class="tex-indent">（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p class="tex-indent">（4）与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
    <p class="tex-indent">（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
    <p class="tex-indent">（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p class="tex-indent">（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
    <p class="tex-indent">（8）涉及商业秘密的。</p>
    <p class="tex-indent">8、约束信息系统自动决策</p>
    <p class="tex-indent">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。</p>
    <h3>六、未成年人的个人信息保护</h3>
    <p class="tex-indent">1、焱十一的服务面向成年人，若您是18周岁以下的未成年人，您将无法使用焱十一服务。焱十一根据国家相关法律法规的规定保护未成年人的个人信息。</p>
    <p class="tex-indent">2、对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
    <p class="tex-indent">3、如果有事实证明未成年人在盗用其未监护人身份信息的情况下注册使用了我们的服务与/或产品，我们会与相关监护人协商，并设法尽快删除相关个人信息。</p>
    <h3>七、您的个人信息如何在全球范围转移</h3>
    <p class="tex-indent">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
    <p class="tex-indent">
      由于我们通过遍布全球的资源和服务器提供全球网络竞拍服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您参与竞拍的拍卖公司所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</p>
    <p class="tex-indent">此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意。</p>
    <h3>八、通知与修订</h3>
    <p class="tex-indent">
      1、我们的个人信息保护政策可能变更。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在焱十一网站、焱十一移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问焱十一以便及时了解最新的隐私政策。
    </p>
    <p class="tex-indent">2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</p>
    <p class="tex-indent">本隐私政策所指的重大变更包括但不限于：</p>
    <p class="tex-indent">（1）我们的服务模式发生重大变化。如处理个人信息的目的、 处理的个人信息类型、个人信息的使用方式等；</p>
    <p class="tex-indent">（2）我们在所有权结构、组织架构等方面发生重大变化。如业 务调整、破产并购等引起的所有者变更等；</p>
    <p class="tex-indent">（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p class="tex-indent">（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p class="tex-indent">（5）我们负责处理个人信息安全的责任岗位、联络方式及投诉渠道发生变化时；</p>
    <p class="tex-indent">（6）个人信息安全影响评估报告表明存在高风险时。</p>
    <p class="tex-indent">3、我们还会将本隐私政策的旧版本存档，供您查阅。</p>
    <p class="tex-indent">
      4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们强烈建议您在每次使用我们服务时都查阅本政策。
    </p>
    <h3>九、如何联系我们</h3>
    <p class="tex-indent">1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。</p>
    <p class="tex-indent">个人信息保护负责人邮箱info@easyebid.com</p>
    <p class="tex-indent">联系电话：【400-616-9988】</p>
    <p class="tex-indent">联系地址：北京市大兴区亦庄经济技术开发区荣京东街3号荣京丽都A座415室。</p>
    <p class="tex-indent">您还可以随时通过访问https://www.easyebid.com在线客服系统或拨打我们的任何一部客服电话等多种方式与我们联系。</p>
    <p class="tex-indent">2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
    <p class="tex-indent">附：个人信息及敏感个人信息示例（非焱十一APP或网站所收集的个人信息，仅作示例说明）</p>
    <p class="tex-indent">
      个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融帐户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
    </p>
    <p class="tex-indent">个人信息举例（出自GB/T 35273—2020 《信息安全技术 个人信息安全规范》</p>
    <img class="info-image" src="../../assets/images/infoimg.png" alt="">
    <p class="tex-indent">个人敏感信息举例（出自GB/T 35273—2020 《信息安全技术 个人信息安全规范》</p>
    <img class="info-image" src="../../assets/images/info-image2.png" alt="">
    <p class="tex-indent">注：本隐私政策版本将于2021年【10】月【8】日正式生效。</p>
    <h4>SDK列表</h4>
    <p class="tex-indent">为保障焱十一相关功能的实现和应用的安全稳定运行，我们会接入由第三方提供的软件开发包（SDK）实现该目的。</p>
    <p class="tex-indent">
      我们会对第三方SDK进行严格的安全检测，并要求合作伙伴采取严格的措施来保护您的个人数据。在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK，并及时在本说明中向您公开说明接入第三方SDK的最新情况。请注意，第三方SDK可能因为版本升级、策略调整等原因导致数据类型存在一些变化，请以其公示的官方说明为准。
    </p>
    <p class="tex-indent">Android端如下：</p>
    <p class="tex-indent">微信</p>
    <p class="tex-indent-o">功能：用于将拍卖活动分享到朋友圈和微信好友；微信支付</p>
    <p class="tex-indent-o">收集个人信息字段：无</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">银联</p>
    <p class="tex-indent-o">功能：用于支付、确认设备安全</p>
    <p class="tex-indent-o">收集个人信息字段：位置信息、IMSI、IMEI、经度、纬度、手机号码</p>
    <p class="tex-indent-o">所属机构：中国银联股份有限公司</p>
    <p class="tex-indent">腾讯地图</p>
    <p class="tex-indent-o">功能：用于辅助位置展示场景，如地址展示</p>
    <p class="tex-indent-o">收集个人信息字段：Wifi信息、位置信息、IMSI、ssid、任务列表、设备版本、MAC地址、bssid、IMEI、手机样式、经度、纬度、网络设备制造商、android_id</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">腾讯定位</p>
    <p class="tex-indent-o">功能：用于GPS定位和IP定位，如搜索附近拍卖会时使用</p>
    <p class="tex-indent-o">收集个人信息字段：经纬度、IP地址、ssid、bssid</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">QQ</p>
    <p class="tex-indent-o">功能：用于将页面分享至QQ好友或朋友圈</p>
    <p class="tex-indent-o">收集个人信息字段：网络身份标识信息、个人常用设备信息、个人财产信息</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">联通</p>
    <p class="tex-indent-o">功能：用于辅助运营、第三方登录、企业协作、广告、支付、推送、身份认证</p>
    <p class="tex-indent-o">收集个人信息字段：私网IPV4地址，私网IPV6地址</p>
    <p class="tex-indent-o">所属机构：中国联合网络通信集团有限公司</p>
    <p class="tex-indent">翼支付</p>
    <p class="tex-indent-o">功能：用于支付</p>
    <p class="tex-indent-o">收集个人信息字段：个人基本信息、个人身份信息、网络身份标识信息、个人常用设备信息</p>
    <p class="tex-indent-o">所属机构：天翼电子商务有限公司</p>
    <p class="tex-indent">腾讯开放</p>
    <p class="tex-indent-o">功能：用于辅助开发者快速接入登录、支付、社交渠道等功能</p>
    <p class="tex-indent-o">收集个人信息字段：个人常用设备信息</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">小米推送</p>
    <p class="tex-indent-o">功能：用于消息推送</p>
    <p class="tex-indent-o">收集个人信息字段：设备唯一标识码、网络状态、网络制式</p>
    <p class="tex-indent-o">所属机构：小米科技有限责任公司</p>
    <p class="tex-indent">腾讯bugly</p>
    <p class="tex-indent-o">功能：云、企业协作、辅助运营</p>
    <p class="tex-indent-o">收集个人信息字段：Wifi信息、MAC地址、IMSI、bssid、IMEI</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">腾讯TBS</p>
    <p class="tex-indent-o">功能：框架</p>
    <p class="tex-indent-o">收集个人信息字段：Wifi信息、MAC地址、IMSI、bssid、IMEI</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">腾讯视频云RTMP</p>
    <p class="tex-indent-o">功能：云</p>
    <p class="tex-indent-o"> 收集个人信息字段：常用设备信息、位置信息、IMEI</p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">腾讯广告</p>
    <p class="tex-indent-o">功能：用于投放广告</p>
    <p class="tex-indent-o">收集个人信息字段：手机样式、设备版本、android_id、系统版本、网络设备制造商、手机名</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">手机腾讯网</p>
    <p class="tex-indent-o">功能：其他</p>
    <p class="tex-indent-o">收集个人信息字段：手机样式、设备版本、系统版本</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">华为推送</p>
    <p class="tex-indent-o">功能：用于消息推送</p>
    <p class="tex-indent-o">收集个人信息字段：设备唯一标识码</p>
    <p class="tex-indent-o">所属机构：华为技术有限公司</p>
    <p class="tex-indent">微博SDK</p>
    <p class="tex-indent-o">功能：分享信息至微博</p>
    <p class="tex-indent-o">收集个人信息字段：MAC地址、IMEI</p>
    <p class="tex-indent-o">所属机构：北京微梦创科网络技术有限公司</p>
    <p class="tex-indent">TBS腾讯浏览服务</p>
    <p class="tex-indent-o">功能：用于投放广告</p>
    <p class="tex-indent-o">收集个人信息字段：手机样式</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">魅族推送</p>
    <p class="tex-indent-o"> 功能：用于消息推送</p>
    <p class="tex-indent-o">收集个人信息字段：当前流量卡IMSI、位置信息</p>
    <p class="tex-indent-o">所属机构：魅族科技有限公司</p>
    <p class="tex-indent">QQ互联</p>
    <p class="tex-indent-o">功能：第三方登录</p>
    <p class="tex-indent-o">收集个人信息字段：无</p>
    <p class="tex-indent-o">所属机构：深圳市腾讯计算机系统有限公司</p>
    <p class="tex-indent">移动运营商认证</p>
    <p class="tex-indent-o">功能：用于第三方登录、验证码登录</p>
    <p class="tex-indent-o">收集个人信息字段：无</p>
    <p class="tex-indent-o">所属机构：中国移动通信集团有限公司</p>
    <p class="tex-indent"> 华为广告
    </p>
    <p class="tex-indent-o">功能：用于广告推荐
    </p>
    <p class="tex-indent-o">收集个人信息字段：匿名用户ID（OAID）
    </p>
    <p class="tex-indent-o">所属机构：华为技术有限公司
    </p>
    <p class="tex-indent">小米Miui
    </p>
    <p class="tex-indent-o">功能：用于广告推荐
    </p>
    <p class="tex-indent-o">收集个人信息字段：匿名用户ID（OAID）
    </p>
    <p class="tex-indent-o">所属机构：小米科技有限责任公司
    </p>
    <p class="tex-indent">美团
    </p>
    <p class="tex-indent-o">功能：用于实现生活服务、分享
    </p>
    <p class="tex-indent-o">收集个人信息字段：无</p>
    <p class="tex-indent-o">所属机构：北京三快在线科技有限公司
    </p>
    <p class="tex-indent">Vivo
    </p>
    <p class="tex-indent-o">功能：用于消息推送
    </p>
    <p class="tex-indent-o">收集个人信息字段：匿名用户ID（OAID）
    </p>
    <p class="tex-indent-o">所属机构：维沃移动通信有限公司
    </p>
    <p class="tex-indent">ColorOS
    </p>
    <p class="tex-indent-o">功能：用于消息推送
    </p>
    <p class="tex-indent-o">收集个人信息字段：匿名用户ID（OAID）
    </p>
    <p class="tex-indent-o">所属机构：OPPO广东移动通信有限公司
    </p>
    <p class="tex-indent"> Zxing二维码扫描
    </p>
    <p class="tex-indent-o"> 功能：扫描二维码，用于扫码登录、扫码打开拍品信息
    </p>
    <p class="tex-indent-o"> 收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：GoogleInc
    </p>
    <p class="tex-indent"> 谷歌
    </p>
    <p class="tex-indent-o"> 功能：用于数据分析、分享、图像处理、广告、辅助开发
    </p>
    <p class="tex-indent-o"> 收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：GoogleInc
    </p>
    <p class="tex-indent"> 魅族
    </p>
    <p class="tex-indent-o"> 功能：用于数据分析、云、广告、推送、支付
    </p>
    <p class="tex-indent-o"> 收集个人信息字段：匿名用户ID（OAID）
    </p>
    <p class="tex-indent-o"> 所属机构：魅族科技有限公司
    </p>
    <p class="tex-indent">chromium
    </p>
    <p class="tex-indent-o">功能：用于辅助开发
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：设备版本、IMSI、设备应用安装列表、网络设备制造商、MAC地址、网络运营商、Wifi信息、位置信息、手机制造商、ssid、bssid、IMEI、android_id、经度、纬度
    </p>
    <p class="tex-indent-o">所属机构：Google Inc
    </p>

    <p class="tex-indent"> caverock
    </p>
    <p class="tex-indent-o">功能：用于辅助开发
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o">所属机构：个人(PaulLeBeau)
    </p>
    <p class="tex-indent"> GSON
    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：GoogleInc
    </p>
    <p class="tex-indent"> ORMLite数据库操作
    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>
    <p class="tex-indent"> Fresco
    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：facebook Inc
    </p>
    <p class="tex-indent"> Facebook
    </p>
    <p class="tex-indent-o"> 功能：用于数据分析、分享、第三方登录
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：facebook Inc
    </p>

    <p class="tex-indent"> Apache

    </p>
    <p class="tex-indent-o"> 功能：辅助开发

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：Apache
    </p>

    <p class="tex-indent"> Glide图片加载
    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：GoogleInc
    </p>

    <p class="tex-indent"> okhttp

    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：SquareInc.

    </p>
    <p class="tex-indent"> Protobuf编解码
    </p>
    <p class="tex-indent-o"> 功能：框架
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o">所属机构：GoogleInc
    </p>

    <p class="tex-indent"> 华为应用升级

    </p>
    <p class="tex-indent-o">功能：升级应用

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：匿名用户ID（OAID）

    </p>
    <p class="tex-indent-o">所属机构：华为技术有限公司
    </p>

    <p class="tex-indent"> getkeepsafe/relinker

    </p>
    <p class="tex-indent-o">功能：框架

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无

    </p>
    <p class="tex-indent-o">所属机构：KeepsafeSoftware Inc.

    </p>

    <p class="tex-indent"> butterknife

    </p>
    <p class="tex-indent-o">功能：框架

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无

    </p>
    <p class="tex-indent-o">所属机构：个人(JakeWharton)

    </p>

    <p class="tex-indent"> rx/internal

    </p>
    <p class="tex-indent-o">功能：框架

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：RxJava Contributors.
    </p>
    <p class="tex-indent"> ijkplayer 视频框架
    </p>
    <p class="tex-indent-o"> 功能：视频播放器
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：上海宽娱数码科技有限公司
    </p>
    <p class="tex-indent"> cmic sso
    </p>
    <p class="tex-indent-o"> 功能：移动认证一键登录
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：中国移动通信集团有限公司
    </p>
    <p class="tex-indent"> cmic sso
    </p>
    <p class="tex-indent-o"> 功能：移动认证一键登录
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：中国移动通信集团有限公司
    </p>
    <p class="tex-indent"> 中国人民银行
    </p>
    <p class="tex-indent-o"> 功能：用于支付
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：个人常用设备信息、位置信息
    </p>
    <p class="tex-indent-o"> 所属机构：中国银联股份有限公司
    </p>

    <p class="tex-indent"> 腾讯wcdb

    </p>
    <p class="tex-indent-o"> 功能：消息存储

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无

    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司

    </p>

    <p class="tex-indent"> 腾讯热修复

    </p>
    <p class="tex-indent-o"> 功能：热修复

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>

    <p class="tex-indent"> 腾讯热修复

    </p>
    <p class="tex-indent-o"> 功能：热修复

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>
    <p class="tex-indent"> 华为钱包
    </p>
    <p class="tex-indent-o"> 功能：用于XXXX充值
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：华为软件技术有限公司
    </p>
    <p class="tex-indent"> iOS端如下：
    </p>
    <p class="tex-indent"> WeAppCore SDK
    </p>
    <p class="tex-indent-o">
      功能：获取用户信息并用于微信小程序的授权
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：地理位置、录音、相册、摄像头、联系人信息、MAC地址
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>

    <p class="tex-indent"> 新浪微博libWeiboSDK
    </p>
    <p class="tex-indent-o">
      功能：用于分享到新浪微博
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：北京微梦创科网络技术有限公司
    </p>

    <p class="tex-indent"> 腾讯qq TencentOpenAPI.framework
    </p>
    <p class="tex-indent-o">
      功能：用于登陆、分享到QQ
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：基础设备信息、app信息
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>

    <p class="tex-indent"> 腾讯地图 QMapKit.framework

    </p>
    <p class="tex-indent-o">
      功能：用于订单物流跟踪，位置展示

    </p>
    <p class="tex-indent-o">
      收集个人信息字段：IMEI、网络类型、手机型号、系统版本号、APP唯一标识、APP名称、APP版本号
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>
    <p class="tex-indent"> JDWeiXinModule
    </p>
    <p class="tex-indent-o">
      功能：微信回调处理
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：无
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>
    <p class="tex-indent"> 微信dylbWeChatSDK.framework
    </p>
    <p class="tex-indent-o">
      功能：用于登录、支付、分享到微信等
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：基础设备信息、app信息
    </p>
    <p class="tex-indent-o"> 所属机构：深圳市腾讯计算机系统有限公司
    </p>
    <p class="tex-indent"> 银联云闪付Apple Pay
    </p>
    <p class="tex-indent-o">
      功能：银联支付
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：终端语言、终端版本、终端分辨率、操作系统名、操作系统版本号、时区、联网方式、是否越狱
    </p>
    <p class="tex-indent-o"> 所属机构：中国银联股份有限公司
    </p>
    <p class="tex-indent"> 联通一键登录account_login_sdk_noui_core.framework
    </p>
    <p class="tex-indent-o">
      功能：一键登录运营商预取
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：手机号、手机品牌、型号、应用包名、私网ipv4地址、私网ipv6地址
    </p>
    <p class="tex-indent-o"> 所属机构：中国联合网络通信集团有限公司
    </p>
    <p class="tex-indent"> 移动一键登录TYRZSDK.framework
    </p>
    <p class="tex-indent-o">
      功能：一键登录运营商预取当前手机号
    </p>
    <p class="tex-indent-o">
      收集个人信息字段：网络类型(WIFI\数据)、网络制式（2G、3G、4G）、设备类型、系统版本、idfv
    </p>
    <p class="tex-indent-o"> 所属机构：中国移动通信集团有限公司
    </p>
    <h3>焱十一帐户注销须知</h3>
    <p class="tex-indent">亲爱的焱十一注册用户：</p>
    <p class="tex-indent-o">您在申请注销流程中点击同意前，应当认真阅读《焱十一帐户注销须知》（以下称“《注销须知》”）。</p>
    <p class="tex-indent-o">请您务必审慎阅读、充分理解《注销须知》中相关条款内容，其中包括：</p>
    <p class="tex-indent-o">1、与您约定免除或限制责任的条款；</p>
    <p class="tex-indent-o">2、其他以粗体标识的重要条款。</p>
    <p class="tex-indent-o">如您对本《注销须知》有任何疑问，可拨打我们的客服电话XXXXXX联系客服，您也可联系在线客服。</p>
    <p class="tex-indent-o">
      【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何约定，请您立即停止帐户注销程序。
      我们在此善意地提醒您，您注销帐户的行为会给您的售后维权带来诸多不便，且注销焱十一帐户后，您的个人信息我们会在焱十一的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。</p>
    <p class="tex-indent-o">1、如果您仍执意注销帐户，您的帐户需同时满足以下条件：</p>
    <p class="tex-indent-o">（1）自愿放弃帐户在焱十一系统中的资产和虚拟权益（包括但不限于帐户余额、帐户权益等），并确保帐户中无拍卖保证金、无欠款；</p>
    <p class="tex-indent-o">（2）帐户当前为有效帐户，非冻结状态；</p>
    <p class="tex-indent-o">（3）帐户内无未完成的订单和服务；</p>
    <p class="tex-indent-o">（4）帐户已经解除与其他网站、其他APP的授权登录或绑定关系；</p>
    <p class="tex-indent-o">（5）帐户无正在进行中的投诉举报或被投诉举报；</p>
    <p class="tex-indent-o">（6）实名帐户已解除实名信息。</p>
    <p class="tex-indent-o">
      2、焱十一帐户一旦被注销将不可恢复，请您在操作之前自行备份焱十一帐户相关的所有信息和数据。请您保存好订单的交易凭证、票据等资料，否则您有可能须支付额外的帐户和订单查询费用，或无法享受后续服务。</p>
    <p class="tex-indent-o">3、在焱十一帐户注销期间，如果您的焱十一帐户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，焱十一有权自行终止本焱十一帐户的注销而无需另行得到您的同意。</p>
    <p class="tex-indent-o">4、注销焱十一帐户，您将无法再使用本焱十一帐户，也将无法找回您焱十一帐户中及与帐户相关的任何内容或信息，包括但不限于：</p>
    <p class="tex-indent-o">（1）您将无法登录、使用本焱十一帐户；</p>
    <p class="tex-indent-o">（2）本焱十一帐户的个人资料和历史信息（包括但不限于用户名、头像、竞拍记录、收藏信息等）都将无法找回；</p>
    <p class="tex-indent-o">
      （3）您通过焱十一帐户使用、授权登录或绑定焱十一帐户后使用的焱十一相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的余额、积分、优惠券、订单、资格、帐户权益及其他卡券等视为您自行放弃，将无法继续使用。您理解并同意，焱十一无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关帐户信息，具体可与我们的客服联系。
    </p>
    <p class="tex-indent-o">6、注销本焱十一帐户并不代表您在本焱十一帐户注销前的帐户行为和相关责任得到豁免或减轻。</p>
    <p class="tex-indent-o">7、焱十一里的所有知识产权归北京艺得在线科技有限公司所有。任何假冒、仿制本平台以及其它侵害本平台知识产权的行为，北京艺得在线科技有限公司保留追究其法律责任的权利。</p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      centerDialogVisible: false
    }
  },
  methods: {
    book () {
      this.centerDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.view-men {
  text-align: left;
  padding: 20px 80px;
  h3 {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .tex-indent {
    text-indent: 2em;
  }
  .info-image {
    width: 600px;
  }
  .tex-indent-o {
    text-indent: 4em;
  }
  .book {
    color: #333;
    text-decoration: underline;
  }
}
</style>
